{
  "company.name": "MCS LLC",

  "contact-us": "Contact us",
  "download": "Download our deck",

  "hero.title": "MCS guarantees security, analysis, training, innovation",
  "hero.description": "MCS LLC is specialised in Cybersecurity, Security and Sensitive Project Management and has the necessary resources, skills and certifications to support clients and meet their needs.",

  "feature.title.security": "Security solution",
  "feature.security.description-first": "All the knowledge of MCS",
  "feature.security.description-second": "MCS, the best security solution and safety partner.",

  "feature.experience.title": "Everything you need",
  "feature.experience.description-first": "Our experience for your benefit",
  "feature.experience.description-second": "A wide range of technologies and cyber services for your bespoken project.",

  "primaryFeatures.name.first": "Cybersecurity",
  "primaryFeatures.description.first": "Protecting computer systems and networks from theft, damage, or unauthorized access.",
  "primaryFeatures.name.second": "Video Management",
  "primaryFeatures.description.second": "Overseeing and organizing video content to ensure smooth operation and accessibility, offering tools for uploading, storing, distributing, and streaming videos, as well as analytics and security features.",
  "primaryFeatures.name.third": "Smart Home",
  "primaryFeatures.description.third": "Design and installation of smart home systems, such as data networks, security systems, and audiovisual equipment.",

  "secondaryFeatures.name.first": "Push to deploy.",
  "secondaryFeatures.description.first": "MCS with Lutron and Crestron support builds professional audio/video/domotic systems easy-to-use",
  "secondaryFeatures.name.second": "Cyber certificates.",
  "secondaryFeatures.description.second": "MCS deploys state-of-art software and certified auditors to assure protected network.",
  "secondaryFeatures.name.third": "Simple use.",
  "secondaryFeatures.description.third": "We guarantee a stable and user-friendly environment.",
  "secondaryFeatures.name.fourth": "Advanced security.",
  "secondaryFeatures.description.fourth": "Video management system, ANPR, facial recognition, biometric tools at your support.",
  "secondaryFeatures.name.fifth": "Powerful environment.",
  "secondaryFeatures.description.fifth": "Empowering people, businesses and societies with data-driven video technology.",
  "secondaryFeatures.name.sixth": "Cloud.",
  "secondaryFeatures.description.sixth": "MCS offers robust cloud services that provide secure, scalable, and reliable storage solutions for data and video technology needs. Monaco Cloud Partner.",

  "stats.title": "Our track record",
  "stats.description-first": "Trusted by multiple of clients",
  "stats.description-second": "worldwide",
  "stats.description-third": "Thanks to your trust MCS LLC continues to improve day by day in order to be on the cutting edge of technology.",

  "stats.name.first": "Engineer for you",
  "stats.value.first": "50+",
  "stats.name.second": "Experience in the field",
  "stats.value.second": "10y+",
  "stats.name.third": "Certifications in our services",
  "stats.value.third": "15+",
  "stats.name.fourth": "Online support",
  "stats.value.fourth": "24/7",

  "cta.title": "So what are you waiting for ?",
  "cta.description": "Click on the button below and fill in the form. Our customer service will answer you as soon as possible.",

  "footer.title": "Footer",

  "contact.title": "Get in touch",
  "contact.subtitle": "Fill in the form for all your requests, our team will contact you as soon as possible.",
  "contact.address": "Address",
  "contact.address.details1": "7742 N. Kendall Drive - Suite #87",
  "contact.address.details2": "Miami, Dade - Florida",
  "contact.phone": "Telephone",
  "contact.phone.details": "+1 (786) 707-5981",
  "contact.email": "Email",

  "contact.return": "Back",
  "contact.form.firstname": "First name",
  "contact.form.lastname": "Last name",
  "contact.form.email": "Email",
  "contact.form.company": "Company",
  "contact.form.phone": "Phone",
  "contact.form.message": "Message",
  "contact.form.send-message": "Send message",
  "contact.form.optional": "Optional",
  "contact.form.max-length": "Max 500 characters",

  "captcha": "Captcha",
  "captcha.loading": "Loading...",

  "mail.sendSuccess": "Your message has been sent successfully",
  "mail.sendError": "An error has occurred",
  "mail.send": "Message sent",
  "mail.notSend": "Error"
}
